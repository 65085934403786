import request from './axios'

// 时卡购买记录
export function getTimeOrderList(params) {
  return request({
    url: '/api/timeUser/timeGoodsUser',
    method: 'get',
    params
  })
}

// 商品列表（时长卡）
export function getTimeGoodsList() {
  return request({
    url: '/api/timeGoods/onSale',
    method: 'get'
  })
}

// 获取时长的明细记录列表
export function getTimeRecord(params) {
  return request({
    url: '/api/timeUser/timeUserTransaction',
    method: 'get',
    params
  })
}
// 购买时长卡
export function mpPurchase(data) {
  return request({
    url: '/api/timeUser/timeGoodsUser/mpPurchase',
    method: 'post',
    data
  })
}

// 兑换时长卡
export function exchangeTime(data) {
  return request({
    url: '/api/redeem-code/exchange',
    method: 'put',
    data
  })
}

// 兑换记录
export function redeemedRecord(data) {
  return request({
    url: '/api/redeem-code/redeemed',
    method: 'get',
    params: data
  })
}
