<!--
 * 购买记录
-->
<template>
  <div class="container order-page">
    <van-nav-bar title="购买记录" left-arrow @click-left="onClickLeft" />
    <div class="date-picker-container" @click="show = true">
      <span>{{ currentDateString }}</span>
      <img
        class="arrow-down-icon"
        :src="require('@/assets/icon/arrow-down-icon.png')"
      />
    </div>
    <div class="order-list-container" ref="warp">
      <Empty v-if="!list.length && reqFlag"></Empty>
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getTimeOrderList"
      >
        <div
          class="order-item-container"
          v-for="item in list"
          :key="item"
          :title="item"
        >
          <div class="title">
            <img :src="require('@/assets/icon/order-title-icon.png')" />
            <div class="order-no">订单号：{{ item.orderNo }}</div>
            <div class="line"></div>
            <div class="copy-btn" @click="onCopy(item.orderNo)">复制</div>
          </div>
          <div class="order-info">
            <div class="top">
              <div class="order-name">{{ item.goodsName }}</div>
              <div class="buy-time">购买时间：{{ item.createTime }}</div>
            </div>
            <div class="bottom">
              <div class="price">¥{{ item.salePrice }}</div>
              <div class="expiration-time">到期时间：{{ item.expireTime }}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-datetime-picker
        :value="createTime"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onSelectDate"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getTimeOrderList } from '@/api/goods'
import Empty from '@/components/Empty.vue'
export default {
  name: 'order',
  components: {
    Empty
  },
  data() {
    return {
      reqFlag: false, // 修复暂无数据显示顺序
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      createTime: new Date(),
      show: false,
      list: [],
      pageNum: 1,
      pageSize: 10,
      loading: false,
      finished: false
    }
  },
  computed: {
    currentDateString() {
      return this.$dayjs(this.createTime).format('YYYY年MM月')
    }
  },
  methods: {
    onSelectDate(val) {
      this.createTime = val
      this.show = false
      this.getTimeOrderList(1)
      // 获取列表数据
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onCopy(content) {
      this.$copyText(content).then(() => {
        this.$toast('已复制')
      })
    },
    getTimeOrderList(num) {
      this.reqFlag = true
      this.$toast.loading({
        message: '加载中',
        forbidClick: true
      })
      this.loading = true
      if (num) {
        this.pageNum = num
      }
      if (this.pageNum === 1) {
        this.$refs.warp.scrollTo(0, 0)
        this.finished = false
      }
      const createTime = this.$dayjs(this.createTime).format('YYYY-MM')
      console.log(createTime)
      const params = {
        createTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getTimeOrderList(params)
        .then((res) => {
          if (res.status === 200) {
            if (this.pageNum === 1) {
              this.list = []
            }
            this.list = [...this.list, ...res.data.list]
            if (this.list.length >= res.data.totalItems) {
              this.finished = true
            }
            this.pageNum++
          }
          this.$toast.clear()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.order-page {
  .date-picker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 187px;
    color: @text-color-1;
    .arrow-down-icon {
      margin-left: 2px;
      width: 14px;
      height: 14px;
    }
  }
  .order-list-container {
    padding: 6px 10px;
    height: calc(100vh - 44px - 68px);
    margin: 0 16px;
    overflow: auto;
    background: @bg-color-2;
    border-radius: 4px;
    &::-webkit-scrollbar {
      display: none;
    }
    .order-item-container {
      &:not(:last-child) {
        border-bottom: 1px solid #333;
      }
      padding: 15px 0;
      .title {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: @text-color-2;
        img {
          margin-right: 4px;
          width: 10px;
          height: 13px;
        }
        .line {
          margin: 0 8px;
          &::after {
            content: '';
            display: block;
            width: 1px;
            height: 12px;
            background: #979797;
          }
        }
        .copy-btn {
          color: @text-color-1;
        }
      }
      .order-info {
        padding: 12px 15px;
        background-color: @bg-color-3;
        border-radius: 2px;
        color: @text-color-2;
        font-size: 12px;
      }
      .top,
      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .top {
        margin-bottom: 10px;
      }
      .order-name {
        color: @text-color-1;
        font-weight: bold;
        font-size: 15px;
      }
      .price {
        color: @text-color-orange;
        font-weight: bold;
        font-size: 15px;
      }
    }
  }
}
</style>
